<template>
    <div id="table">

      <!-- breadcrumb -->
      <nav class="text-sm font-semibold mb-6" aria-label="Breadcrumb">
        <ol class="list-none p-0 inline-flex">
          <li class="flex items-center text-blue-500">
            <router-link to="Team">
              <a class="text-gray-700">Team</a>
            </router-link>
            <svg class="fill-current w-3 h-3 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
          </li>
          <li class="flex items-center">
            <router-link :to="$route.params.dashboard">

              <a class="text-gray-600">{{ $route.params.dashboard }}</a>
            </router-link>
          </li>
        </ol>
      </nav>

      <!-- breadcrumb end -->

      <div class="flex flex-wrap -mx-3">
        <div class="w-full px-3">
          <p class="text-xl font-semibold mb-4">All Transactions</p>
          <div class="flex">
            <div class="mb-4">
              <select 
                class="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                v-model="month"
                @change="switchSelect($event,null,'month')">
                <option value="1">01 - January</option>
                <option value="2">02 - February</option>
                <option value="3">03 - March</option>
                <option value="4">04 - April</option>
                <option value="5">05 - May</option>
                <option value="6">06 - June</option>
                <option value="7">07 - July</option>
                <option value="8">08 - August</option>
                <option value="9">09 - September</option>
                <option value="10">10 - October</option>
                <option value="11">11 - November</option>
                <option value="12">12 - December</option>
              </select>
            </div>
            <div class="mb-4">
              <input
                name="year"
                v-model="year"
                @change="switchSelect($event,null,'year')"
                class="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                type="number" min="1900" max="2099" step="1" 
                required
              />
            </div>
          </div>
          <div class="w-full bg-white border rounded-lg p-4">
            <!-- ERROR -->
            <div v-if="error_message" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
              <p class="font-bold">Be Warned</p>
              <p>{{error_message}}</p>
            </div>
            <!-- LIST -->
            <table class="w-full table-fixed">
              <thead>
                <tr class="bg-gray-100 border rounded-md flex justify-between  items-center px-2 py-1 mb-1">
                  <td class="w-8/12 sm:w-4/12"><span class="text-blue-600  ">Titre</span></td>
                  <td class="w-2/12 hidden sm:block"><span class="text-blue-600 ">De</span></td>
                  <td class="w-2/12 hidden sm:block"><span class="text-blue-600">Pour</span></td>
                  <td class="w-2/12 hidden sm:block"><span class="text-blue-600  ">Catégorie</span></td>
                  <td class="w-5/12 sm:w-1/12 text-right"><span class="text-blue-600">€</span></td>
                  <td>
                    <button class="bg-transparante p-0 text-sm focus:outline-none invisible">
                      <font-awesome-icon icon="fa-solid fa-pen" class="text-gray-600 hover:text-black" />
                    </button>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items"  class="w-full bg-gray-100 border rounded-lg flex justify-between items-center px-2 py-1 mb-2" :key="'item-'+item.id">
                  <td class="w-4/12">
                    <p v-if="edit_transaction != item.id" class="font-normal text-md">{{item.label}}</p>
                    <input
                      @change="switchSelect($event,item.id,'label')"
                      v-if="edit_transaction == item.id"
                      v-model="item.label"
                      class="form-control block px-1 py-1 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-0 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-full"
                      type="text"
                    />
                    <p class="text-xs">{{new Date(item.date).toLocaleDateString("fr-FR")}}</p>
                  </td>
                  <td class="hidden sm:block w-3/12 sm:w-2/12">
                    <div v-if="edit_transaction != item.id" >
                      <span class="text-sm">{{item.from}}</span>
                    </div>
                    <select
                      v-if="edit_transaction == item.id"
                      @change="switchSelect($event,item.id,'from')"
                      class="form-control block px-1 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ">
                      <option> - </option>
                      <option v-for="someone in people" :value="someone.name" :key="'someone-'+someone.name" :selected="someone.name == item.from ? true : false" >{{someone.name}}</option>
                    </select>
                  </td>
                  <td class="hidden sm:block w-3/12 sm:w-2/12">
                    <div v-if="edit_transaction != item.id" >
                      <span class="text-sm" v-for="p in people_selected[item.id]" :key="p">{{p}} </span>
                    </div>
                    <vue-multi-select
                      v-if="edit_transaction == item.id"
                      @open="() => {reloadData = false}"
                      @close="switchSelectMultiple(item.id,'for')"
                      ref="multiSelect"
                      btnClass="form-control whitespace-nowrap block px-0 py-0 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-0 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-full"
                      v-model="people_selected[item.id]"
                      :options="options"
                      :btnLabel="btnLabel"
                      class="w-full"
                      :selectOptions="people_array">
                      <template v-slot:option="{option}">
                        <input type="checkbox" :checked="option.selected" />
                        <span class="whitespace-nowrap ml-2">{{option.name}}</span>
                      </template>
                    </vue-multi-select>
                  </td>
                  <td class="block sm:hidden w-4/12">
                    <span class="text-xs mr-1 text-gray-700 font-light">De:</span>
                    <div v-if="edit_transaction != item.id" >
                      <span class="text-xs text-gray-800 font-bold">{{item.from}}</span>
                    </div>
                    <select
                      v-if="edit_transaction == item.id"
                      @change="switchSelect($event,item.id,'from')"
                      class="form-control block px-1 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ">
                      <option> - </option>
                      <option v-for="someone in people" :value="someone.name" :key="'someone-'+someone.name" :selected="someone.name == item.from ? true : false" >{{someone.name}}</option>
                    </select>
                    <span class="text-xs mr-1 text-gray-700 font-light">Pour:</span>
                    <div v-if="edit_transaction != item.id" >
                      <span class="text-xs text-gray-800 font-bold" v-for="p in people_selected[item.id]" :key="p">{{p}} </span>
                    </div>
                    <vue-multi-select
                      v-if="edit_transaction == item.id"
                      @open="() => {reloadData = false}"
                      @close="switchSelectMultiple(item.id,'for')"
                      ref="multiSelect"
                      btnClass="form-control whitespace-nowrap block px-0 py-0 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-0 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-full"
                      v-model="people_selected[item.id]"
                      :options="options"
                      :btnLabel="btnLabel"
                      class="w-full"
                      :selectOptions="people_array">
                      <template v-slot:option="{option}">
                        <input type="checkbox" :checked="option.selected" />
                        <span class="whitespace-nowrap ml-2">{{option.name}}</span>
                      </template>
                    </vue-multi-select>
                  </td>
                  <td class="w-2/12 hidden sm:block" >
                    <span v-if="edit_transaction != item.id" >{{item.categorie}}</span>
                    <select 
                      v-if="edit_transaction == item.id"
                      @change="switchSelect($event,item.id,'categorie')"
                      class="form-control block px-1 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                      <option> - </option>
                      <option v-for="categorie in categories" :value="categorie.categorie" :key="'categorie-'+categorie.categorie" :selected="categorie.categorie == item.categorie ? true : false" >{{categorie.categorie}}</option>
                    </select>
                  </td>
                  <td class="w-1/12 text-right">
                    <span v-if="edit_transaction != item.id" class="font-semibold text-lg" :class="item.amount >= 0 ? 'text-green-500': 'text-red-500'">{{item.amount}}€</span>
                    <input
                      @change="switchSelect($event,item.id,'amount')"
                      v-if="edit_transaction == item.id"
                      v-model="item.amount"
                      class="form-control block px-1 py-1 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-0 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-full"
                      type="number"
                      step="0.01"
                      />
                    </td>
                  <td class="self-stretch justify-start">
                    <div class="flex flex-row justify-between text-right ">
                      <button v-if="edit_transaction == item.id" @click="cancelOrRemove(item.id)" class="bg-transparante p-0 pr-1 text-xs focus:outline-none">
                        <font-awesome-icon icon="fa-solid fa-trash " class="text-red-500 hover:text-red-800" />
                      </button>

                      <button v-if="edit_transaction == item.id" @click="editOn(item.id)" type="submit" class="bg-transparante p-0 text-sm focus:outline-none">
                        <font-awesome-icon icon="fa-solid fa-check" class="text-green-500 hover:text-green-800" />
                      </button>
                      <button v-if="edit_transaction != item.id" @click="editOn(item.id)" class="bg-transparante p-0 text-sm focus:outline-none">
                        <font-awesome-icon icon="fa-solid fa-pen" class="text-gray-600 hover:text-black" />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>

            </table>
            <!-- EMPTY -->
            <div v-if="!loading && !items.length" class="bg-white flex justify-center">
              <img class="object-contain object-center h-64" src="/assets/empty.jpg"/>
            </div>

            <!-- Loading -->
            <div v-if="loading" class="flex justify-center items-center space-x-2">
                <svg class="w-12 h-12 animate-spin text-indigo-400" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 4.75V6.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M17.1266 6.87347L16.0659 7.93413" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M19.25 12L17.75 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M17.1266 17.1265L16.0659 16.0659" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M12 17.75V19.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M7.9342 16.0659L6.87354 17.1265" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M6.25 12L4.75 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M7.9342 7.93413L6.87354 6.87347" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
            </div>

            <!-- ADD -->
            <form v-if="add_open" v-on:submit.prevent="onSubmit" class="w-full bg-gray-100 border rounded-lg flex justify-between items-center px-3 py-1 mb-2">
              <div class="w-5/12 sm:w-4/12">
                <p class="font-semibold text-xs">Titre:</p>
                <input
                  required
                  v-model="newTransaction['label']"
                  class="form-control block px-1 py-1 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-0 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-full"
                  type="text"
                  />
                  <input
                  required
                  v-model="newTransaction['date']"
                  class="form-control block px-1 py-1 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-0 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-full"
                  type="date"
                  />
              </div>
              <div class="flex flex-col sm:flex-row w-4/12 sm:w-6/12 items-center">
                <div class="w-full sm:w-2/3">
                  <div class="flex flex-col md:flex-row">
                    <div class="p-1 w-full">
                        <p class="font-semibold text-xs">De:</p>
                        <select
                          v-model="newTransaction['from']"
                          class="form-control block px-1 py-1 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ">
                          <option>     </option>
                          <option v-for="someone in people" :value="someone.name" :key="'someone2-'+someone.name" >{{someone.name}}</option>
                        </select>
                    </div>
                      
                    <div  class="p-1 w-full">
                        <p class="font-semibold text-xs">Pour:</p>
                        <vue-multi-select
                          required
                          ref="multiSelect"
                          v-model="newTransaction['for']"
                          :options="options"
                          :btnLabel="btnLabel"
                          btnClass="form-control whitespace-nowrap block px-0 py-0 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-0 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-full"
                          class="w-full"
                          :selectOptions="people_array">
                          <template v-slot:option="{option}" >
                            <input type="checkbox" :checked="option.selected" />
                            <span class="whitespace-nowrap">{{option.name}}</span>
                          </template>
                        </vue-multi-select>
                        <!-- https://vue-multiselect.js.org/#sub-getting-started -->
                    </div>
                  </div>
                </div>
                <div class=" w-full sm:w-1/3">
                  <div class="p-1 w-full">
                      <p class="font-semibold text-xs">Catégorie:</p>
                      <select
                        v-model="newTransaction['categorie']"
                        class="form-control block px-1 py-1 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                        <option>           </option>
                        <option v-for="categorie in categories" :value="categorie.categorie" :key="'categorie2-'+categorie.categorie">{{categorie.categorie}}</option>
                      </select>
                  </div>
                </div>
              </div>
              <div class="w-2/12 sm:w-1/12 ml-1">
                <p class="font-semibold text-xs">€</p>
                <input
                  required
                  v-model="newTransaction['amount']"
                  class="form-control block px-1 py-1 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-0 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-full"
                  type="number"
                  step="0.01"
                  />
              </div>
              <div class="flex flex-col">
                <button @click="clearAdd()"  class="bg-transparante p-2 text-xl focus:outline-none">
                  <font-awesome-icon icon="fa-solid fa-xmark" class="text-red-500 hover:text-red-800" />
                </button>
                <button type="submit" class="bg-transparante p-2 text-xl focus:outline-none">
                  <font-awesome-icon icon="fa-solid fa-check" class="text-green-500 hover:text-green-800" />
                </button>
              </div>
            </form>



            <div class="flex justify-end">
              <button @click="() => add_open = true" class="border border-white  hover:border-black  focus:outline-none rounded-lg p-1 text-dark font-bold">
                <svg class="h-6 w-6 fill-current" viewBox="0 0 20 20">
                  <path d="M14.613,10c0,0.23-0.188,0.419-0.419,0.419H10.42v3.774c0,0.23-0.189,0.42-0.42,0.42s-0.419-0.189-0.419-0.42v-3.774H5.806c-0.23,0-0.419-0.189-0.419-0.419s0.189-0.419,0.419-0.419h3.775V5.806c0-0.23,0.189-0.419,0.419-0.419s0.42,0.189,0.42,0.419v3.775h3.774C14.425,9.581,14.613,9.77,14.613,10 M17.969,10c0,4.401-3.567,7.969-7.969,7.969c-4.402,0-7.969-3.567-7.969-7.969c0-4.402,3.567-7.969,7.969-7.969C14.401,2.031,17.969,5.598,17.969,10 M17.13,10c0-3.932-3.198-7.13-7.13-7.13S2.87,6.068,2.87,10c0,3.933,3.198,7.13,7.13,7.13S17.13,13.933,17.13,10"></path>
                </svg>
              </button>
            </div>
          </div> 
          <div class="w-full bg-white border rounded-lg p-4 mt-3 flex justify-between items-center">
            <div>
              <p class="text-md">
                <table>
                  <tr v-for="man in total_by_people" :key="'man-'+man.name" >
                    <td v-if="man.total > 0" class="text-sm ">{{man.name}} doit payer</td>
                    <td v-if="man.total < 0" class="text-sm ">{{man.name}} doit recevoir</td>
                    <td v-if="man.total > 0" class="text-sm pl-3 text-red-500 font-bold" >{{Math.abs(Number(man.total).toFixed(2))}} €</td>
                    <td v-if="man.total < 0" class="text-sm pl-3 text-green-500 font-bold" >{{Math.abs(Number(man.total).toFixed(2))}} €</td>
                  </tr>
                </table>
              </p>
            </div>
            <div class="text-right">
              <p class="font-semibold text-xl">Total</p>
              <span class="ml-2 text-red-500">{{Number(total).toFixed(2)}}€</span>
            </div>
            <div class="text-right">
              <p class="font-semibold text-xl">En commun</p>
              <span class="ml-2 text-red-500">{{Number(total_commun).toFixed(2)}}€</span>
            </div>
            
          </div>

          <div class="w-full bg-white border rounded-lg p-4 mt-3 flex justify-between items-center">
            <div class="w-full">
              <p class="text-md font-semibold">
                Paramètre du dashboard
              </p>
              <div class="flex flex-col md:flex-row w-full">
                <span class="md:self-center mr-2">Participant:</span>
                <input 
                  v-model="newParticipant"
                  placeholder="Nom"
                  class=" form-control block px-1 py-1 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-0 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-full"/>
                <button @click="addPerson()" class=" md:ml-2 bg-blue-500 hover:bg-blue-600 focus:outline-none rounded-lg px-6 py-1 text-white font-semibold shadow">Ajouter</button>
              </div>
              <div class="flex flex-col md:flex-row w-full mt-2">
                <span class="md:self-center mr-2">Categorie:</span>
                <input 
                  v-model="newCategorie"
                  placeholder="Categorie"
                  class=" form-control block px-1 py-1 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-0 w-full rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-full"/>
                <button @click="addCategorie()" class=" md:ml-2 bg-blue-500 hover:bg-blue-600 focus:outline-none rounded-lg px-6 py-1 text-white font-semibold shadow">Ajouter</button>
              </div>
              <div class="flex flex-1 flex-col w-full mt-2 justify-center">
                
                <form v-on:submit.prevent="onSubmitFileForm">
                  <span class="mt-2 text-sm mr-2">Importer un relevé bancaire:</span>

                  <select
                    v-model="banque"
                    name="banque"
                    class="form-control block px-1 py-1 mt-2 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                    <option value="CM">Crédit Mutuel</option>
                    <option value="Fortuneo">Fortuneo</option>
                    <option value="LCL">LCL</option>
                  </select>

                  <select
                    v-model="file_from"
                    name="file_from"
                    class="form-control block px-1 py-1 mt-2 text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                    <option v-for="someone in people" :value="someone.name" :key="'someone-'+someone.name" >{{someone.name}}</option>
                  </select>

                  <label class="w-full flex flex-col items-center px-4 py-2 mt-2 bg-white text-blue rounded-lg shadow-lg tracking-wide border border-blue cursor-pointer hover:bg-blue-600 hover:text-white">
                      <svg class="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                      </svg>
                      <input type='file' id="banquefile" class="hidden" />
                  </label>

                  <button class="bg-blue-500 hover:bg-blue-700 mt-2 text-white font-bold py-2 px-4 rounded" type="submit">
                    Ajouter
                  </button>
                </form>

              </div>
              
            </div>
          </div>

        </div>
      </div>
    </div>
</template>

<script>
import request from '../utils/request'
import vueMultiSelect from 'vue-multi-select';
import 'vue-multi-select/dist/lib/vue-multi-select.css';

export default {
    name: 'Table',
    props: ['id_dashboard'],
    data() {
        return {
          error_message: "",
          loading: true,
          month: new Date().getMonth()+1,
          year: new Date().getFullYear(),
          items: [],
          categories: [],
          people: [],
          people_array: [],
          edit_transaction: -1,

          total_by_people: [],
          total: 0,

          people_selected: {},
          btnLabel: people_selected => {
            if (!people_selected.length) return "-"
            let txt = ""
            people_selected.forEach((p,i)=>{
              if (i) txt += ", "
              txt += p.substring(0, 4)
            })
            return txt
          },
          options: {
            multi: true,
          },
          add_open: false,
          newTransaction: {
            date: "",
            amount: "",
            from: "",
            categorie: "",
            label: "",
            for: [],
          },

          reloadData:true,
          intervalTotal: null,
          intervalList: null,

          newParticipant: "",
          newCategorie: "",
          total_commun:0,

          banque: "CM",
          file_from: "",
        }
    },
    watch:{
      $route (){
          this.resetValue()
          this.loadDataCollector()
      }
    },

    mounted() {
      this.loadDataCollector()
    },
    beforeDestroy () {
      this.clearDataCollector()
    },
    methods: {
      resetValue() {
        this.loading= true
        this.month= new Date().getMonth()+1
        this.year= new Date().getFullYear()
        this.items= []
        this.categories= []
        this.people= []
        this.people_array= []
        this.edit_transaction= -1

        this.total_by_people= []
        this.total= 0

        this.people_selected= {}
        this.options= {
          multi: true,
        },
        this.add_open= false
        this.newTransaction= {
          date: "",
          amount: "",
          from: "",
          categorie: "",
          label: "",
          for: [],
        }
        this.reloadData=true
        this.intervalTotal= null
        this.intervalList= null
        this.newParticipant= ""
        this.newCategorie= ""
        this.total_commun=0

        this.banque="CM"
      },
      switchSelectMultiple(id,key) {
        this.error_message = ""
        if (id){
          let data = { id: id, col: key, value: this.people_selected[id]}
          request.post("/transactions/update",data,{"Content-Type":'application/json',"headers":{"5050-token":this.$store.getters.token}})
            .then(response => {
              if (response && response.data && response.data.status == "success"){
                this.error_message = ""
                this.reloadData = true;
                this.loadTotal()
              }else{
                this.error_message = response.data.message
                this.reloadData = true;
              }
            }).catch( err => {
              this.error_message = err?.response?.data?.message || err.message || "Internal Error"
              this.reloadData = true;
            })
        }
      },
      switchSelect(event,id,key) {
        this.error_message = ""
        if (id){
          let data = { id: id, col: key, value: event.target.value}
          request.post("/transactions/update",data,{"Content-Type":'application/json',"headers":{"5050-token":this.$store.getters.token}})
            .then(response => {
              if (response && response.data && response.data.status == "success"){
                this.error_message = ""
                this.loadTotal()
              }else{
                this.error_message = response.data.message
              }
            }).catch( err => {
              this.error_message = err?.response?.data?.message || err.message || "Internal Error"
            })
        }else{
          if (key == "month"){
            this.month = event.target.value
            this.loadTransaction()
            this.loadTotal()
          }else if (key == "year"){
            this.year = event.target.value
            this.loadTransaction()
            this.loadTotal()
          }
        }
      },
      clearDataCollector(){
        clearInterval(this.intervalTotal)
        clearInterval(this.intervalList)
      },
      loadDataCollector(){
        this.loading = true;
        this.loadCategorie()
        this.loadPeople()

        this.loadTotal()
        this.loadTransaction()
        
        this.intervalList = setInterval(this.loadTransaction, 2000);
        this.intervalTotal = setInterval(this.loadTotal, 2000);
      },
      loadCategorie(){
        request.get(`/dashboard/categories/${this.$route.params.dashboard}`,{"headers":{"5050-token":this.$store.getters.token}})
          .then(response => {
            if (response && response.data && response.data.status == "success"){
              this.categories = response.data.categorie
            }else{
              this.categories = []
            }
          })
          .catch((err)=>{this.categories = [];this.error_message = err})
      },
      loadPeople(){
        request.get(`/dashboard/people/${this.$route.params.dashboard}`,{"headers":{"5050-token":this.$store.getters.token}})
          .then(response => {
            if (response && response.data && response.data && response.data.status == "success"){
              this.people = response.data.people
              let p = []
              response.data.people.forEach(element => {
                p.push(element.name)
              });
              this.people_array = p
            }else{
              this.people = [];
              this.people_array = [];
            }
          })
          .catch((err)=>{this.people = [];this.people_array = [];this.error_message = err})
      },
      loadTransaction(){
        if (this.reloadData){
          request.get(`/transactions/${this.$route.params.dashboard}/${this.month}/${this.year}`,{"headers":{"5050-token":this.$store.getters.token}})
          .then(response => {
            if (response && response.data){
              this.items = response.data;
              response.data.forEach(i => {
                this.people_selected[i.id] = i.for?.split(',')
              });
            }
            this.loading = false;
          })
          .catch((err)=>{
            this.error_message = err
            this.loading = false;
          })
        }
      },
      loadTotal(){
        if (this.reloadData){
          request.get(`/transactions/total/${this.$route.params.dashboard}/${this.month}/${this.year}`,{"headers":{"5050-token":this.$store.getters.token}})
          .then(response => {
            if (response && response.data && response.data.transactions){
              this.total_commun = 0;

              let total = response.data.transactions
              let fix = {}
              this.total = response.data.total

              total.forEach(( row )=>{
                let tab_for = row.for.split(",")
                let repartition = row.amount/tab_for.length;
                tab_for.forEach(( p_f )=>{
                  if (fix[p_f] == undefined) fix[p_f] = 0
                  if (fix[row.from] == undefined) fix[row.from] = 0

                  fix[p_f] += repartition
                  fix[row.from] -= repartition
                })

                if (tab_for.length > 1)
                  this.total_commun += row.amount
                
              })

              this.total_by_people = []
              Object.keys(fix).forEach((e)=>{
                this.total_by_people.push({name:e,total:fix[e]*-1})
              })
            }
          })
          .catch((err)=>{
            this.error_message = err
          })
        }
      },
      clearAdd(){
        this.newTransaction={date: "",amount: "",from: "",categorie: "",label: "",for: []}
        this.add_open = false
      },
      onSubmit: function (event) {
        if (event) {
          event.preventDefault()
          

          let data = {
            date: this.newTransaction['date'],
            label: this.newTransaction['label'],
            from: this.newTransaction['from'],
            for: this.newTransaction['for'],
            categorie: this.newTransaction['categorie'],
            amount: this.newTransaction['amount']
          }

          request.post(`/transactions/add/${this.$route.params.dashboard}`,data,{"Content-Type":'application/json',"headers":{"5050-token":this.$store.getters.token}})
            .then(response => {
              if (response && response.data && response.data.status == "success"){
                this.items.push(response.data.transaction)
                this.people_selected[response.data.transaction.id] = response.data.transaction.for
                this.add_open = false
                this.error_message = ""
                this.loadTotal()
              }else{
                this.error_message = response.data.message
                //event.target.elements.password.value = ""
              }
              
            }).catch( err => {
              this.error_message = err?.response?.data?.message || err.message || "Internal Error"
              //event.target.elements.password.value = ""
            })
        }
      },

      cancelOrRemove(id) {
        this.edit_transaction = -1
        this.reloadData = true;
        request.post("/transactions/remove",{id:id},{"Content-Type":'application/json',"headers":{"5050-token":this.$store.getters.token}})
          .then(response => {
            if (response && response.data){
              this.error_message = ""
              this.loadTransaction()
              this.loadTotal()
            }else{
              this.error_message = response.data.message
            }
          }).catch( err => {
            this.error_message = err?.response?.data?.message || err.message || "Internal Error"
          })
      
      },
      editOn(id) {
        if (this.edit_transaction != id){
          this.edit_transaction = id
          this.reloadData = false;
        }else{
          this.edit_transaction = -1
          this.reloadData = true
        }
      },
      addCategorie() {
        if (this.newCategorie)
          request.post("/dashboard/addCategorie",{categorie:this.newCategorie,id_dashboard:this.$route.params.dashboard},{"Content-Type":'application/json',"headers":{"5050-token":this.$store.getters.token}})
          .then(response => {
            if (response && response.data){
              this.error_message = ""
              this.newCategorie = ""
              this.loadCategorie()
            }else{
              this.error_message = response.data.message
            }
          }).catch( err => {
            this.error_message = err?.response?.data?.message || err.message || "Internal Error"
          })
      },
      addPerson() {
        if (this.newParticipant)
          request.post("/dashboard/addPerson",{name:this.newParticipant,id_dashboard:this.$route.params.dashboard},{"Content-Type":'application/json',"headers":{"5050-token":this.$store.getters.token}})
          .then(response => {
            if (response && response.data){
              this.error_message = ""
              this.newParticipant = ""
              this.loadPeople()
            }else{
              this.error_message = response.data.message
            }
          }).catch( err => {
            this.error_message = err?.response?.data?.message || err.message || "Internal Error"
          })
      },
      onSubmitFileForm: function (event) {
        if (event) {
          event.preventDefault()

          let formData = new FormData();
          console.log(this.file_banque)
          formData.append("files", document.getElementById('banquefile').files[0]);
          formData.append("banque", this.banque);
          formData.append("file_from", this.file_from);
          formData.append("id_dashboard", this.$route.params.dashboard);

          console.log(formData)
          request.post('/dashboard/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              "5050-token":this.$store.getters.token
            }
          }).then(response => {
              if (response && response.data){
                this.error_message = ""
              }else{
                this.error_message = response.data.message
              }
            }).catch( err => {
              this.error_message = err?.response?.data?.message || err.message || "Internal Error"
            })
        }
      },

      sendUploadRequest() {
        alert("gnagnagna")
        let formData = new FormData();
        console.log(this.file_banque)
        formData.append("files", this.file_banque.files[0]);
        formData.append("banque", this.banque);
        formData.append("file_from", this.file_from);
        formData.append("id_dashboard", this.$route.params.dashboard);
        
        console.log(formData)

      }
    },
    components:{vueMultiSelect}
}
</script>